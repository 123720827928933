@import 'public/styles/utils';

.admin-header {
    background-color: $very-dark-blue !important;
    transition: ease 0.1s all;
    position: fixed !important;

    @include respond-to(medium) {
        width: calc(100% - 220px) !important;
        margin-left: 220px;
    }

    &__toolbar {
        min-height: 55px !important;

        a {
            color: $white;

            &:hover,
            &:active,
            &:focus {
                color: $white;
            }
        }
    }

    &__toggle {
        @include respond-to(medium) {
            display: none !important;
        }
    }

    &__title {
        display: flex;
        align-items: center;

        img {
            height: 36px;
            width: auto;
            padding-right: 12px;
        }

        span {
            padding-top: 2px;
        }
    }
}
