@import 'public/styles/utils';

.preview-groups {
  position: relative;
  margin-top: 30px;

  &__header {
    max-width: 1100px;
    width: 90%;
    margin: 0 auto;
  }

  &__content {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 10px 0 20px;

    @include respond-to(medium) {
      margin: 20px 0 30px 0;
      flex-flow: row wrap;
    }
  }

  .preview-more {
    margin: 25px 0;

    a {
      border: 1px solid $very-dark-blue;
      color: $very-dark-blue;

      &:hover {
        background-color: $very-dark-blue;
        color: $white;
      }
    }

    @include respond-to(small) {
      margin: 30px 0;
    }
  }
}
