@import 'public/styles/utils';

.footer {
  position: relative;

  &__salutation {
    width: 70%;
    max-width: 1100px;

    @include respond-to(medium) {
      text-align: center;
    }

    @include respond-to(small) {
      width: 100%;
    }
  }

  &__content {
    padding: 20px 5% 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: $very-dark-blue;

    i {
      color: $light-yellow-background;
      width: 30px;
      font-size: 24px;

      &.fa-envelope,
      &.fa-youtube-play {
        font-size: 22px;
      }

      &.fa-map-marker {
        font-size: 26px;

        &:before {
          margin-left: 3px;
        }
      }
    }

    @include respond-to(medium) {
      align-items: center;
    }

    .section-text {
      color: $white;
    }
  }

  &__contact {
    display: flex;
    width: 100%;
    justify-content: center;

    @include respond-to(small) {
      flex-direction: column;
    }

    &__left,
    &__right {
      display: flex;
      flex-direction: column;

      @include respond-to(large) {
        flex-direction: row;
      }
    }

    &__left {
      @include respond-to(medium) {
        margin-right: 40px;
      }

      @include respond-to(large) {
        .contact-item:first-child {
          margin-right: 40px;
        }
      }
    }

    &__right {
      @include respond-to(large) {
        .contact-item:first-child {
          margin-right: 40px;
        }
      }
    }

    .contact-item {
      display: flex;
      align-items: center;
      height: 32px;
      font-weight: 300;

      @include respond-to(large) {
        height: auto;
      }
    }
  }

  &__copyright {
    width: 100%;
    background-color: $very-dark-blue;
    padding-top: 40px;
    box-sizing: content-box;

    i {
      padding-right: 5px;
      color: $white;
      font-size: 14px;
      width: auto;

      &.fa-youtube-play {
        margin-left: 20px;
      }

      &.fa-facebook {
        margin-left: 10px;
      }

      &.fa-youtube-play,
      &.fa-facebook {
        font-size: 16px;
      }
    }

    p {
      color: $white;
      font-size: 14px;
      position: relative;

      @include respond-to(medium) {
        text-align: center;
      }
    }
  }
}
