@import 'public/styles/utils';

.contact {
  &__main {
    padding: 0 0 20px 0;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &__map {
      width: 100%;
      position: relative;
      height: 50vh;
    }

    &__label {
      text-transform: uppercase;
      color: $very-dark-blue;
      font-size: 16px;

      @include respond-to(medium) {
        font-size: 14px;
      }

      &:not(:first-of-type) {
        margin-top: 30px;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;
      max-width: 1100px;
      padding: 25px 25px 0;

      @include respond-to(small) {
        flex-wrap: wrap;
      }

      & > div {
        width: 100%;

        @include respond-to(medium) {
          justify-content: space-between;

          &:first-of-type {
            width: 50%;
          }

          &:nth-of-type(2) {
            width: 40%;
          }
        }
      }

      .contact-form {
        .contact-label {
          font-weight: 400;
          font-size: 14px;
          color: $dark-grey;

          @include respond-to(medium) {
            font-size: 12px;
            padding: 20px 25px 10px 0;
          }

          @include respond-to(small) {
            padding-top: 25px;
          }
        }

        .contact-input input,
        .contact-input textarea {
          margin-bottom: 2px;

          &:before {
            background-color: $dark-grey;
          }

          &:hover:not(.MuiInput-disabled-20):before {
            height: 1px;
            background-color: $dark-grey;
          }

          &:after {
            background-color: $light;
          }

          @include respond-to(small) {
            font-size: 16px;
          }
        }

        button {
          transition: ease 0.3s all;

          &:disabled {
            background-color: $very-dark-blue-background-dark !important;
          }
        }

        .message-wrapper {
          display: flex;
          align-items: center;
          position: relative;
          min-height: 46px;
          padding-top: 6px;
          max-width: calc(100% - 100px);

          .message {
            &.success-message {
              color: $green;
            }

            &.error-message {
              color: $warm-peach;
            }
          }
        }

        .fa-circle-o-notch {
          animation: spin 0.9s infinite linear;
          font-size: 20px;
        }

        .contact-email {
          margin-top: 15px;
          display: flex;
          flex-wrap: wrap;

          span {
            margin-right: 5px;
          }

          .fa-envelope {
            color: $light-yellow-background;
            font-size: 16px;
            margin-right: 5px;
          }

          a {
            color: $very-dark-blue;
            font-weight: 700;
          }
        }

        @include respond-to(small) {
          padding-bottom: 20px;
        }

        @include respond-to(medium) {
          padding-right: 30px;
        }
      }

      .contact-details {
        @include respond-to(medium) {
          padding: 10px 0 20px 25px;
        }

        @include respond-to(small) {
          padding-top: 10px;
        }

        &__wrapper {
          margin-top: 10px;

          &.donations {
            .contact-details__wrapper__text {
              line-height: 1.5;

              &:not(:first-of-type) {
                margin-top: 15px;
              }

              i {
                font-size: 14px;
                font-style: normal;
              }
            }
          }

          &.documents {
            p {
              line-height: 1.4;

              a {
                text-decoration: underline;
                color: $very-dark-blue;

                &:hover,
                &:active,
                &:focus {
                  text-decoration: underline !important;
                }
              }
            }
          }

          i {
            font-size: 10px;
            color: $very-dark-blue;
            padding-right: 10px;
          }

          span {
            &:not(.regular) {
              font-weight: 700;
            }
          }

          .regular {
            padding-right: 10px;
          }

          &__text {
            line-height: 2.4;
          }

          &__schedule {
            line-height: 1.5;

            span {
              border-bottom: 1px solid $light-yellow-background;
            }
          }
        }

        @include respond-to(small) {
          padding-top: 10px;
        }

        @include respond-to(medium) {
          border-left: 1px solid $aluminium;
          padding-left: 30px;
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
