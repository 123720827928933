@import 'public/styles/utils';

a:hover,
a:active,
a:focus {
  text-decoration: none !important;
}

.upcoming-events {
  position: relative;

  label {
    text-transform: uppercase;
    margin-bottom: 1px;
    color: $very-dark-blue;
    font-weight: 800;
  }

  &__days {
    padding: 3px 6px 1px 0;
    min-height: 30px;
    display: flex;
    flex-direction: column;
  }

  .upcoming-event {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 4px;
    color: $almost-black;

    &__date {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-shadow: 0 0 3px 0 $box-shadow-color;
      background: $light-yellow-background;
      border-radius: 3px;
      font-size: 16px;
      line-height: 15px;
      text-transform: uppercase;
      padding: 6px 8px;

      span {
        &:nth-of-type(2) {
          padding-left: 3px;
        }
      }

      @include respond-to(small) {
        padding: 6px;
      }
    }

    &__day {
      font-weight: 800;

      @include respond-to(medium) {
        padding-right: 5px;
        border-right: 2px solid $white;
      }

      @include respond-to(small) {
        border-right: 2px solid $white;
        padding-right: 3px;
      }

      &__medium {
        @include respond-to(small) {
          display: none;
        }
      }

      &__small {
        @include respond-to(medium) {
          display: none;
        }
      }
    }

    &__time {
      font-weight: 600;

      @include respond-to(small) {
        font-size: 14px;
        padding-left: 3px;
      }

      @include respond-to(medium) {
        padding-left: 5px;
      }
    }

    &__title {
      font-size: 16px;
      margin-left: 6px;
      font-weight: 600;
      color: $very-dark-blue;
      line-height: 20px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }

    &.special {
      img {
        height: 40px;
        width: 37px;
        margin-right: 12px;
        margin-bottom: 2px;
      }

      .upcoming-event__date {
        padding: 4px 5px;
        margin: 2px;
        font-size: 14px;
      }

      .upcoming-event__title {
        width: 160px;
        font-size: 16px;
        position: relative;
        text-align: center;
        top: -3px;
        left: 3px;
        line-height: 19px;
      }
    }

    &__center {
      display: flex;
      flex-direction: column;
    }
  }

  &:hover {
    .upcoming-events__more {
      display: block;
    }
  }

  &__more {
    @include respond-to(medium) {
      display: none;
    }

    transition: opacity 0.3s ease;
    position: absolute;
    top: 18px;
    right: -30px;
    padding: 10px 0 10px 20px;

    i {
      font-size: 22px;
      color: $light;
      font-weight: 500;
      animation: bounce 2s ease;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(-10px);
  }
}
