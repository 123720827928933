@import 'public/styles/utils';

.preview-announcements {
  background-color: $light-grey;
  display: flex;

  &__content {
    width: 90%;
    margin: 10px auto 0;
    max-width: 1100px;
  }

  label {
    text-transform: uppercase;
    margin-bottom: 5px;
    color: $white;
    font-weight: 800;
    color: $light-yellow-background;
  }

  &__header {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .announcement-header {
      background-color: $light-yellow-background;
      text-transform: uppercase;
      padding: 6px 20px;
      font-size: 16px;
      font-weight: 700;
      border-radius: 2px;
    }
  }

  &__message {
    margin-top: 5px;

    &__title {
      text-align: center;
      color: $very-dark-blue;
      font-size: 18px;
      font-weight: 700;
    }

    &__date {
      text-align: center;
      font-weight: 300;
    }

    &__content {
      margin-top: 25px;
      font-size: 16px;
      white-space: pre-wrap;
    }
  }

  .preview-more {
    margin: 25px 0 30px;

    a {
      border: 1px solid $very-dark-blue;
      color: $very-dark-blue;

      &:hover {
        background-color: $very-dark-blue;
        color: $white;
      }
    }
  }
}
