@import 'public/styles/utils';

th,
td {
  font-size: 12px !important;
}

.admin-table {
  font-size: 14px;
  margin-top: 10px;

  @include respond-to(medium) {
    margin-top: 20px;
  }

  &__topbar {
    padding: 0 0 5px;
    display: flex;
    align-items: center;

    & > span {
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &__toolbar {
    transition: ease 0.3s all;

    &.colored {
      background-color: $light;
    }

    .actions {
      margin-left: auto;
    }
  }

  &__buttons {
    margin-left: auto;

    & > button {
      margin-left: 10px;
    }

    button {
      &.icon {
        background-color: $lightish-yellow-background;
        min-width: 36px;

        span {
          font-size: 24px;
          font-weight: 700;
        }

        &:hover {
          background-color: $light;
        }
      }

      &.text {
        background-color: $very-dark-blue-background-darker;
        color: $white;

        &:hover {
          background-color: $very-dark-blue;
        }
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &__image {
    height: 32px;
    width: auto;
    cursor: pointer;

    &.large {
      height: 86px;
    }
  }

  &__popover-toggle {
    cursor: pointer;
  }

  &__popover {
    margin: 4px;
  }

  &__extra-dates {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: relative;
      top: 2px;
    }
  }

  &__row {
    cursor: default;

    &.editable {
      cursor: pointer;
      pointer-events: auto;

      &:hover {
        background-color: $light-grey;
      }
    }
  }

  .pages {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .page {
      cursor: pointer;
      padding: 10px;
      color: $very-dark-blue;
      font-size: 16px;

      &.active {
        font-weight: 900;
      }
    }
  }
}

.admin-section {
  &.inset {
    padding: 5px 0 5px 5px;
  }
}

.admin-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  & > div {
    margin: 0 5px;
  }
}

.admin-input label {
  font-weight: 400;
  font-size: 14px;
  color: $dark-grey;

  @include respond-to(medium) {
    font-size: 12px;
  }
}

.admin-dialog {
  .MuiDialog-paper {
    @include respond-to(medium) {
      min-width: 465px;
      max-width: 465px;
    }
  }
  &__button {
    font-weight: 700 !important;
    font-size: 14px !important;
  }
}

.admin-input input,
.admin-input textarea {
  margin-bottom: 2px;

  &:before {
    background-color: $dark-grey;
  }

  &:hover:not(.MuiInput-disabled-20):before {
    height: 1px;
    background-color: $dark-grey;
  }
}

.admin-input {
  & > div:after {
    background-color: $light !important;
    height: 2px !important;
  }

  & > div:before {
    &:hover {
      background-color: $dark-grey;
    }
  }

  &.date {
    input {
      font-size: 12px !important;
    }
  }
}

.admin-input.select {
  display: flex;
  flex-direction: column-reverse;
}
