@import 'public/styles/utils';

.messages {
  .page-content {
    padding: 25px 25px 0;
    max-width: 1100px;
    margin: 0 auto;

    .coming-soon {
      text-align: center;
      font-size: 20px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .message {
      &__title {
        color: $very-dark-blue;
        font-weight: 800;
        font-size: 30px;
        margin-bottom: 20px;
      }

      &__youtube {
        max-width: 780px;
      }

      &__description {
        font-size: 16px;
        margin-top: 20px;
        white-space: pre-wrap;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $aluminium;
        padding-bottom: 40px;
      }

      &:not(:first-of-type) {
        padding-top: 40px;
      }
    }

    .pages-container {
      p {
        text-align: center;
        margin-top: 30px;
        font-style: italic;
      }

      .pages {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .page {
          cursor: pointer;
          padding: 10px;
          color: $very-dark-blue;
          font-size: 16px;

          &.active {
            font-weight: 900;
          }
        }
      }
    }
  }
}
