@import 'public/styles/utils';

.diagram {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @include respond-to(small) {
        position: relative;
        justify-content: center;
    }

    img {
        width: 40vw;
        height: 40vw;

        @include respond-to(medium) {
            width: 15vw;
            height: 15vw;
            margin: 0 1.5%;
        }

        @include respond-to(large) {
            max-width: 210px;
            max-height: 210px;
        }
    }
}
