@import 'public/styles/utils';

.pastors {
  .page-content {
    padding: 15px 25px 0;
    max-width: 1100px;
    margin: 0 auto;
  }

  .pastor {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 50px;
    }

    &__photo {
      width: 330px;
      height: 330px;
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
      text-align: center;
      background-color: $very-dark-blue;

      @include respond-to(small) {
        width: 100%;
        max-height: 380px;
      }

      i {
        color: $white;
        font-size: 330px;
      }
    }

    &__details {
      padding: 20px 5px;
    }

    &__name {
      span {
        display: block;

        &:first-of-type {
          font-size: 22px;
          font-weight: 800;
          text-transform: uppercase;
        }

        &:last-of-type {
          color: $desaturated-cyan;
          font-weight: 300;
          font-size: 16px;
        }
      }

      padding-bottom: 10px;
      border-bottom: 1px solid $light;
    }

    &__description {
      padding-top: 20px;
      font-size: 16px;
    }

    &:nth-of-type(even) {
      .pastor__photo {
        @include respond-to(medium) {
          order: 2;
        }
      }
    }
  }

  @include respond-to(medium) {
    .page-content {
      padding-top: 40px;
    }

    .pastor {
      flex-direction: row;

      &__details {
        padding: 20px 30px;
        width: 100%;
      }

      &__name {
        span {
          &:first-of-type {
            font-size: 20px;
          }

          &:last-of-type {
            font-size: 14px;
          }
        }
      }

      &__description {
        font-size: 16px;
      }
    }
  }
}
