/* Color theme #1 */
$feather: #77c9d4;
$marine: #57bc90;
$forest: #015249;
$sleek-grey: #a5a5af;

/* Color theme #2 */
$cerulean: #4484ce;
$aluminium: #d9d9d9;
// $light: #f9cf00;
// $light: #ffcc00;
$light: #e5d7ca;
$tangerine: #f19f4d;
$olive: #555b47;

/* Color theme #3 */
$dodger-blue: #4188ff;
$summer-sky: #286da8;
$warm-peach: #cd5360;
$field: #b37d4e;
$breeze: #438496;

/* Color theme #4 */
$very-dark-cyan: #014852;
// $very-dark-blue: #01446b;
$very-dark-blue: #4d4d4d;
$darker-desaturated-cyan: #404e55;
$desaturated-cyan: #406469;
$grayish-lime-green: #e6f1e6;

/* General colors */
$white: #ffffff;
$black: #000000;
$almost-black: #191919;
$light-grey: #f6f6f6;
$dark-grey: #939393;
$darker-grey: #838383;
$green: #5ea758;
$faded-green: #b2b096;

/* RGBAs */
$dark-shadow: rgba(0, 0, 0, 0.4);
$dark-background: rgba(0, 0, 0, 0.8);
$lighter-background: rgba(255, 255, 255, 0.2);
$light-background: rgba(255, 255, 255, 0.5);
$almost-white-background: rgba(255, 255, 255, 0.9);
$warm-peach-background: rgba(205, 83, 96, 0.7);
$dodger-blue-background: rgba(65, 136, 255, 0.9);
$dodger-blue-light-background: rgba(65, 136, 255, 0.8);
$dodger-blue-lightest-background: rgba(65, 136, 255, 0.1);
$breeze-background: rgba(67, 132, 150, 0.7);
// $light-yellow-background: rgba(249, 207, 0, 0.95);
$light-yellow-background: rgba(222, 202, 186, 0.95);
// $lighter-yellow-background: rgba(249, 207, 0, 0.7);
$lighter-yellow-background: rgba(222, 202, 186, 0.7);
// $lightish-yellow-background: rgba(249, 207, 0, 0.8);
$lightish-yellow-background: rgba(222, 202, 186, 0.8);
$lightest-yellow-background: rgba(249, 207, 0, 0.2);
$box-shadow-color: rgba(39, 57, 68, 0.3);
// $beige-background: rgba(238, 223, 204, 0.3);
$beige-background: rgba(222, 202, 186, 0.3);
// $very-dark-blue-background-lighter: rgba(1, 68, 107, 0.3);
$very-dark-blue-background-lighter: rgba(77, 77, 77, 0.3);
// $very-dark-blue-background-light: rgba(1, 68, 107, 0.7);
$very-dark-blue-background-light: rgba(77, 77, 77, 0.7);
// $very-dark-blue-background-dark: rgba(1, 68, 107, 0.8);
$very-dark-blue-background-dark: rgba(77, 77, 77, 0.8);
// $very-dark-blue-background-darker: rgba(1, 68, 107, 0.9);
$very-dark-blue-background-darker: rgba(77, 77, 77, 0.9);
