@import 'public/styles/utils';

.admin-login-wrapper {
  background-color: $very-dark-blue;
  width: 100%;
  height: 100%;
}

.admin-login {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  text-align: center;
  background-color: $light-grey;
  padding: 15px 20px 10px;
  box-sizing: content-box;
  border-radius: 2px;
  box-shadow: 0 0 3px 0 $box-shadow-color;

  @include respond-to(small) {
    width: 75%;
  }

  p {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px;
    color: $very-dark-blue;
  }

  &__credentials {
    height: 160px;

    .label {
      font-weight: 400;
      font-size: 14px;
      padding-left: 0;
      color: $darker-grey;

      @include respond-to(medium) {
        font-size: 12px;
      }
    }

    input {
      margin-bottom: 4px;
      font-size: 14px;

      &:before {
        background-color: $dark-grey;
      }

      &:hover:not(.MuiInput-disabled-20):before {
        height: 1px;
        background-color: $dark-grey;
      }

      &:after {
        background-color: $light;
      }
    }

    button {
      transition: ease 0.3s all;
      font-size: 14px;

      &:disabled {
        background-color: $lightish-yellow-background !important;
      }
    }
  }

  &__error {
    color: $warm-peach;
    padding: 8px 5px 5px;
    font-size: 12px;
  }
}
