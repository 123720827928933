@import 'public/styles/utils';

.logo {
  position: absolute !important;
  transition: ease 0.3s all;
  left: 10px;
  top: 8px;

  @include respond-to(medium) {
    left: 15px;
    top: 10px;
  }

  &__left {
    margin: 0 8px;
    padding: 8px !important;
    margin: 0 12px 0 0 !important;
    opacity: 1;
    visibility: visible;

    @include respond-to(medium) {
      display: none;
    }
  }

  img {
    width: 50px;
    height: 50px;
    position: relative;
    top: -2px;
    transition: ease 0.3s all;
  }

  &.hide-logo {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

nav {
  border-color: transparent !important;
  background: none !important;
  min-height: 50px;
  transition: background-color 0.5s ease-out;

  &.colored {
    .container-fluid {
      .navbar-header {
        .navbar-toggle,
        .navbar-toggle:focus,
        .navbar-toggle:hover,
        .navbar-toggle:active {
          background-color: $very-dark-blue-background-dark !important;
        }
      }
    }

    @include respond-to(medium) {
      background-color: $white !important;
      box-shadow: 0 0 3px 0 $box-shadow-color;

      .container-fluid {
        .navbar-header {
          .navbar-toggle {
            background-color: transparent;
            padding: 9px 10px;
            margin-top: 8px;
          }
        }
      }
    }

    a {
      color: $very-dark-blue !important;
    }

    li:not(.contact) a {
      &:before {
        background-color: $very-dark-blue !important;
      }
    }
  }

  &:not(.colored) {
    li.active {
      @include respond-to(medium) {
        color: $white !important;
      }
    }
  }

  &.open {
    background-color: $white !important;
    box-shadow: 0 0 3px 0 $box-shadow-color !important;
    padding-bottom: 10px;

    &:not(.colored) .icon-bar {
      background-color: $very-dark-blue !important;
    }
  }

  .container-fluid {
    .navbar-header {
      .navbar-toggle {
        border: none;
        margin-right: 12px;
        padding: 13px 10px;
        transition: background-color 0.3s ease;
        border-radius: 100%;
        margin-top: 15px;

        &.dark {
          .icon-bar {
            background: $very-dark-blue;
          }
        }

        .icon-bar {
          background: $white;
          width: 24px;
          height: 3px;
          transition: ease 0.3s all;
        }

        &:hover,
        &:active,
        &:focus {
          background: transparent !important;
        }

        .top {
          transform: translateY(7px) rotateZ(45deg);
        }

        .bottom {
          transform: translateY(-7px) rotateZ(-45deg);
        }

        .middle {
          width: 0;
        }

        &.collapsed {
          .top {
            transform: none;
          }

          .bottom {
            transform: none;
          }

          .middle {
            width: 24px;
          }
        }
      }
    }

    @include respond-to(small) {
      .navbar-collapse {
        box-shadow: none !important;

        a {
          color: $very-dark-blue !important;
        }
      }
    }
  }

  .navbar-collapse {
    border: none;

    .nav {
      float: none;
      display: block;
      text-align: center;
      display: flex;
      flex-direction: column;

      @include respond-to(medium) {
        height: 65px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:before {
          display: none;
        }
      }

      &__center {
        @include respond-to(medium) {
          height: 36px;
        }
      }

      li {
        justify-content: center;
        display: flex;
        margin-bottom: 4px;
        font-size: 16px;

        &.home {
          @include respond-to(medium) {
            display: none;
          }
        }

        &:not(.logo) {
          @media screen and (min-width: 768px) and(max-width: 850px) {
            padding: 4px 0px !important;
            margin: 0 6px !important;

            a {
              padding: 6px 4px !important;
              letter-spacing: 0.5px;
            }
          }

          @media screen and (min-width: 851px) and(max-width: 997px) {
            padding: 4px 1px !important;
          }
        }

        @include respond-to(medium) {
          display: inline-block;
          float: none;
          vertical-align: middle;
          font-size: 14px;

          &:not(.logo) {
            position: relative;
            padding: 4px 10px;
            margin: 0 8px;
            top: 6px;
          }
        }

        &.logo {
          display: none;

          a {
            &:hover,
            &:focus,
            &:active {
              &:before {
                transform: scaleX(0) !important;
              }
            }
          }

          @include respond-to(medium) {
            display: inline-block;

            a {
              padding: 0;
            }
          }
        }

        a {
          color: $very-dark-blue;
          text-transform: uppercase;
          font-weight: 900;
          letter-spacing: 1px;
          transition: color 0.3s ease;
          position: relative;
          padding: 6px 5px;

          &:hover,
          &:focus,
          &:active {
            color: $very-dark-blue;
          }
        }

        &:not(.contact) a {
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $very-dark-blue;
            visibility: hidden;
            border-radius: 5px;
            transform: scaleX(0);
            transition: 0.2s linear;
          }

          &:hover,
          &:focus,
          &:active {
            &:before {
              visibility: visible !important;
              transform: scaleX(1);
            }
          }
        }

        &.active {
          color: $very-dark-blue;

          a {
            background: transparent !important;
            border-radius: 2px;
            color: inherit;

            &:before,
            &:active:before,
            &:focus:before,
            &:hover:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              bottom: 0;
              left: 0;
              background-color: currentColor;
              visibility: visible !important;
              border-radius: 5px;
              transform: scaleX(1);
            }

            &:active,
            &:focus,
            &:hover {
              color: currentColor !important;
            }
          }
        }

        &.contact a {
          border: 1px solid currentColor;
          border-radius: 2px;
          transition: background-color 0.3s linear;
          padding: 6px 10px 4px;

          @media screen and (min-width: 860px) {
            padding: 6px 20px 4px;
          }

          @include respond-to(small) {
            margin-top: 10px;
          }

          &:before,
          &:hover:before,
          &:active:before,
          &:focus:before {
            background-color: transparent;
          }

          &:hover {
            background-color: $very-dark-blue-background-lighter;
          }
        }

        &.padded-left {
          margin-left: 10px;
        }
      }
    }

    @include respond-to(medium) {
      .navbar {
        .navbar-collapse {
          text-align: center;
        }
      }
    }
  }
}
