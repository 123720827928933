@import 'public/styles/utils';

.about {
  &__main {
    margin: 0 auto;
    max-width: 1100px;
    padding: 25px 25px 0;

    &__general {
      line-height: 24px;
      padding: 10px 0;

      .section-title {
        margin-bottom: 20px;
      }

      div {
        font-size: 16px;

        span {
          font-weight: 700;
          color: $black;
        }
      }

      .section-subtitle {
        font-size: 24px;
        display: inline-block;
        text-transform: uppercase;
        font-weight: 700;
        // color: $very-dark-blue;
        color: $faded-green;
        padding: 2px 0;
        border-radius: 2px;
        margin: 25px 0 10px;
      }

      .section-subcontent {
        border-left: 2px solid $lighter-yellow-background;
        padding-left: 10px;

        &__objectives {
          display: flex;
          align-items: center;
          color: $very-dark-blue;
          margin-top: 4px;

          .number {
            color: $faded-green;
            font-size: 24px;
            font-weight: 900;
            opacity: 0.5;
            margin-right: 10px;
            width: 10px;
            display: flex;
            justify-content: center;
          }

          .objective {
            color: $very-dark-blue;
          }
        }
      }

      .value {
        text-transform: uppercase;
        border-bottom: 1px solid $light-yellow-background;
        font-weight: 700;
        color: $very-dark-blue;
      }
    }

    hr {
      margin-top: 40px;
      margin-bottom: 30px;
    }

    &__vision {
      .section-title {
        margin: 10px 0;
      }

      .vision-content {
        position: relative;
        padding-top: 15px;

        @include respond-to(medium) {
          display: flex;
          flex-direction: row;
          padding: 15px 0 0 0;
        }

        &__wrapper {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          margin: 0;

          @include respond-to(medium) {
            padding-right: 20px;
            width: auto;
            margin: 0;
            width: 30%;
          }

          .vision-title {
            margin: 6px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            box-shadow: 0 0 3px 0 $box-shadow-color;
            background-color: $white;
            height: 40px;
            width: 100%;
            cursor: pointer;
            transition: ease 0.3s all;
            position: relative;

            &.active,
            &:hover {
              box-shadow: 0 0 3px 0 $light;
              color: $very-dark-blue;
            }

            span {
              text-align: center;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 16px;
            }

            i {
              position: absolute;
              right: 10px;
              top: 12px;
              font-size: 18px;
              transition: ease 0.3s all;

              &:before {
                content: '\f107';
              }

              &.up:before {
                content: '\f106';
              }
            }

            @include respond-to(medium) {
              width: 100%;

              span {
                font-size: 14px;
              }

              i {
                display: none;
              }
            }
          }

          &__text {
            @include respond-to(medium) {
              display: none !important;
              width: 70%;
            }
          }
        }

        .vision-section {
          padding: 2px;

          &__title {
            color: $very-dark-blue;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 3px 0;
          }

          &__subtitle {
            font-weight: 400;
            font-size: 14px;
            color: $darker-grey;

            @include respond-to(medium) {
              font-size: 12px;
            }
          }

          &__paragraph {
            padding: 5px 10px;
            font-size: 16px;

            i {
              color: $very-dark-blue;
              font-size: 10px;
              padding: 0px 6px 0 1px;
              position: relative;
              top: -1px;
            }

            &:nth-of-type(2n) {
              background-color: $beige-background;
            }

            @include respond-to(medium) {
              font-size: 14px;
            }
          }

          border-bottom: 1px solid $aluminium;
          padding: 15px 0;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          &:first-child {
            padding-top: 0;
          }
        }

        .vision-text {
          padding: 2px 0 0 10px;
          border-left: 2px solid $very-dark-blue;
          max-width: 70%;

          @include respond-to(small) {
            display: none;
          }
        }
      }
    }

    h5 {
      text-transform: uppercase;
      font-weight: 800;
      color: $very-dark-blue;
      font-size: 28px;
      position: relative;
      display: inline-block;
    }
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
