@import 'public/styles/utils';

.announcements {
  .page-content {
    padding: 30px 25px 0;
    max-width: 1100px;
    margin: 0 auto;

    .coming-soon {
      text-align: center;
      font-size: 20px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .announcement {
			margin-bottom: 0;

      &__top {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @include respond-to(small) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &__title {
        color: $very-dark-blue;
        font-weight: 800;
        font-size: 30px;
      }

      &__date {
        background-color: $light-yellow-background;
        text-transform: uppercase;
        padding: 4px 8px;
        border-radius: 5px;
        font-weight: 700;

        @include respond-to(small) {
          margin-top: 10px;
        }

        @include respond-to(medium) {
          margin-left: 25px;
        }
      }

      &__message {
        font-size: 16px;
				margin-top: 30px;
				white-space: pre-wrap;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $aluminium;
        padding-bottom: 40px;
      }

      &:not(:first-of-type) {
        padding-top: 40px;
      }
    }
  }
}
