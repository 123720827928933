@import '../public/styles/utils';

@font-face {
  font-family: 'Bahnschrift';
  src: url(./bahnschrift.ttf) format('truetype');
}

html {
  font-size: 14px;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Bahnschrift', sans-serif;
  scroll-behavior: smooth;
  overflow-y: auto;
  font-size: 14px;
  height: 100%;

  #root {
    height: 100%;

    .main {
      height: 100%;
    }

    .root-child {
      height: 100%;
    }
  }
}

.page-wrapper {
  background-color: $light-grey;
  overflow-y: auto;
  min-height: calc(100vh - 298px);
  padding-bottom: 40px;

  @include respond-to(medium) {
    min-height: calc(100vh - 234px);
  }

  @include respond-to(large) {
    min-height: calc(100vh - 176px);
  }

  h3 {
    position: relative;
    text-align: center;
    margin: 0;
  }

  hr {
    border-top-color: $aluminium;
  }
}

.page-content {
  padding: 20px;
  padding-top: 60px;

  @include respond-to(medium) {
    padding: 20px 40px 10px;
  }
}

.preview-more {
  text-align: center;

  a {
    padding: 8px 16px;
    border-radius: 1px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.3s ease all;
    position: relative;
  }
}

.disable-hover,
.disable-hover * {
  pointer-events: none !important;
}

.icon-bar {
  background: $white;
  width: 24px;
  height: 3px;
  transition: ease 0.3s all;

  &.top {
    transform: translateY(7px) rotateZ(45deg);
  }

  &.bottom {
    transform: translateY(-7px) rotateZ(-45deg);
  }

  &.middle {
    width: 0;
  }
}
