@import 'public/styles/utils';

.mission {
  &__main {
    padding: 40px 0 0 0;
    position: relative;
  }

  &__item {
    &:not(:last-child) {
      padding-bottom: 50px;
    }

    &__flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__title {
      font-size: 28px;
      text-transform: uppercase;
      font-weight: 800;
      color: $very-dark-blue;
      text-align: center;
      margin: 10px;
      color: $very-dark-blue;
      width: auto;
      margin: 0 auto;
      margin-bottom: 20px;

      @include respond-to(medium) {
        font-size: 32px;
      }
    }

    &__heading {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      img {
        height: 280px;
        width: 100%;
        object-fit: cover;
        padding-bottom: 10px;
        font-family: 'object-fit: contain;';
      }

      @include respond-to(medium) {
        width: 65%;
        padding-right: 15px;

        img {
          height: 350px;
          padding-bottom: 0;
          object-position: 50% 25%;
        }
      }
    }

    &__workers {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 15px 15px 0;

      img {
        width: 75px;
        height: 75px;
        border-radius: 100%;
        object-fit: cover;
        box-shadow: 0 0 3px 0 $box-shadow-color;
        font-family: 'object-fit: contain;';
      }

      .workers-info {
        display: flex;
        flex-direction: column;
        padding-left: 10px;

        a {
          color: $very-dark-blue;
          font-size: 16px;

          .workers-name {
            font-weight: 600;

            i {
              font-size: 12px;
              padding-left: 5px;
              position: relative;
              top: -1px;
            }
          }

          &:hover {
            i {
              color: $almost-black;
            }
          }
        }

        .workers-subtitles {
          span {
            font-weight: 400;
            font-size: 14px;
            display: block;
          }
        }
      }
    }

    &__content {
      padding: 0 25px;
      max-width: 1100px;
      margin: 0 auto;

      .section {
        font-size: 16px;
        padding-bottom: 15px;

        span {
          display: block;
          padding: 0 0 8px;
        }

        &__title {
          display: inline-block !important;
          color: $very-dark-blue;
          font-weight: 800;
          font-size: 18px;
          text-transform: uppercase;
          border-bottom: 2px solid $light;
          margin-bottom: 2px;
          padding-bottom: 0 !important;
        }
      }
    }

    &__map {
      width: 100%;
      position: relative;
      height: 180px;

      @include respond-to(medium) {
        width: 35%;
        height: auto;
        margin-top: 65px;
      }
    }
  }
}
