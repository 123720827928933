@import 'public/styles/utils';

.page-title {
  text-transform: uppercase;
  position: relative;
  text-align: center;
  border-radius: 1px;
  margin: 0 auto;
  height: 330px;
  box-shadow: 0 0 3px 0 $box-shadow-color;
  width: 100%;

  @include respond-to(medium) {
    height: 350px;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 0.7;
    transition: ease 0.3s all;
    font-family: 'object-fit: contain;';
    background-size: cover !important;
  }

  h3 {
    font-size: 50px;
    display: inline;
    position: relative;
    top: 130px;
    color: $light;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
    z-index: 1;
    letter-spacing: -1px;

    @include respond-to(medium) {
      top: 178px;
    }
  }

  &:after {
    content: '';
    background-color: $very-dark-blue-background-dark;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}
