@import 'public/styles/utils';

.schedule {
  height: 70px;
  border-radius: 3px;
  border: 1px solid $white;
  padding: 10px 10px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $very-dark-blue-background-light;

  &.canceled {
    height: auto;
  }

  @include respond-to(small) {
    margin-bottom: 15px;
  }

  &__dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    color: $white;

    @include respond-to(medium) {
      font-size: 20px;
    }

    &.canceled {
      font-size: 18px;
    }
  }

  &__sunday {
    border-right: 2px solid $light;
    padding: 0 5px 0 0;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__next-sunday {
    text-transform: uppercase;
    border-right: 2px solid $light;
    font-weight: 500;
    padding: 0 5px;
  }

  &__hours {
    display: flex;
    flex-direction: row;

    span {
      &:first-of-type {
        padding: 0 4px;
        font-weight: 700;
      }

      &:nth-of-type(2) {
        font-weight: 700;

        &:before {
          content: '&';
          font-weight: 300;
          padding-right: 4px;
        }
      }
    }
  }

  label {
    text-transform: uppercase;
    margin-bottom: 2px;
    color: $light;
    font-weight: 800;
  }
}
