@import 'public/styles/utils';

.admin-menu {
    &__wrapper {
        height: 100%;
        background-color: $light-grey;
    }

    &__toolbar {
        height: 55px;
        width: 100%;
    }

    &__list {
        width: 220px;

        span {
            font-weight: 600;
            color: $almost-black;
        }

        a {
            color: $almost-black;
            width: 100%;
        }

        .selected {
            background-color: $aluminium;
        }
    }

    &__permanent {
        display: none;

        @include respond-to(medium) {
            display: flex;
        }
    }
}
