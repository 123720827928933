@import 'public/styles/utils';

.preview-pastor {
  display: flex;
  width: 100%;
  padding: 10px;

  @include respond-to(small) {
    padding: 10px 0;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 308px;

    @include respond-to(medium) {
      max-width: 100%;
    }

    &__photo {
      width: 130px;
      min-width: 130px;
      height: 130px;
      object-fit: cover;
      object-position: center;
      border-radius: 2px;
      border-radius: 100%;
      background-position: center;
      background-size: cover;

      i {
        font-size: 120px;
        color: $white;
      }
    }
  }

  &__details {
    padding-left: 10px;

    span {
      display: block;

      &:first-of-type {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        color: $light;
      }

      &:last-of-type {
        font-weight: 300;
        color: $white;
      }
    }
  }

  @include respond-to(medium) {
    padding: 10px 0;
    width: 30%;

    @media screen and (min-width: 768px) and (max-width: 910px) {
      width: 50%;
    }
  }

  @include respond-to(large) {
    padding: 10px 0;
    width: 33%;
  }
}
