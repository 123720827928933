@import 'public/styles/utils';

.preview-header {
  position: relative;
  margin: 5px 0 25px 0;

  &__content {
    text-transform: uppercase;
    font-weight: 700;

    &__text {
      font-size: 28px;
      color: $white;

      &.dark {
        color: $very-dark-blue;
      }
    }
  }

  &__bottom-border {
    width: 50%;
    height: 2px;
    background-color: $white;

    @include respond-to(small) {
      width: 100%;
    }

    &.dark {
      background-color: $very-dark-blue;
    }
  }
}
