@import 'public/styles/utils';

.admin-empty {
  width: 100%;
  height: 100%;
  background-color: $very-dark-blue;
}

.admin-wrapper {
  height: 100%;

  .admin-content {
    padding: 58px 15px;
    height: 100%;
    width: 100%;
    background-color: $light-grey;
    overflow-y: auto;

    @include respond-to(medium) {
      padding: 58px 25px;
      width: calc(100% - 220px);
      margin-left: 220px;
    }

    &.hidden {
      display: none;
    }
  }

  .admin-home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    padding-top: 55px;

    @include respond-to(medium) {
      padding-left: 220px;
    }

    p {
      padding: 0 20px 20px 40px;
      color: $white;
    }

    h3 {
      padding: 20px 20px 10px 40px;
      color: $white;
      font-weight: 600;
    }
  }
}

.admin-soon {
  padding: 20px 0;
}
