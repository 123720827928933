@import 'public/styles/utils';

.group {
    &__content {
        height: 110px;
        position: relative;
        transition: all 0.3s ease;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-sizing: content-box;
        box-shadow: 0 0 3px 0 $box-shadow-color;

        &__title {
            text-align: center;
            padding: 20px;
            transition: all 0.3s ease;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 28px;
            margin: 10px 0;
            background-color: $very-dark-blue-background-light;
            color: $white;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @include respond-to(small) {
            height: 100px;
            border: none;

            &__title {
                padding: 10px;
                font-size: 26px;
            }
        }

        @include respond-to(medium) {
            &:hover {
                .group__content__title {
                    background-color: $very-dark-blue-background-dark !important;
                }

                &:after {
                    content: '';
                    height: 100%;
                    width: 100%;
                    border: 2px solid $white;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    @include respond-to(medium) {
        width: 50%;

        &__content {
            height: 160px;
            margin: 0;

            &__title {
                margin: 0;
            }
        }
    }
}
