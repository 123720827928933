@import 'public/styles/utils';

.preview-pastors {
  position: relative;
  background-color: $very-dark-blue;
  box-shadow: 0 0 3px 0 $box-shadow-color;

  &__content {
    margin: 20px auto 0;
    max-width: 1100px;
    width: 90%;
    // padding: 20px 0 10px;
    padding: 20px 0;
  }

  &__list {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;

    @include respond-to(medium) {
      justify-content: space-between;
    }
  }

  .preview-more {
    margin: 25px 0;

    a {
      border: 1px solid $white;
      color: $white;

      &:hover {
        background-color: $white;
        color: $very-dark-blue;
      }
    }
  }

  @include resize(small) {
    &__content {
      flex-direction: column;
    }
  }
}
