@import 'public/styles/utils';

.homepage {
  width: 100%;
  height: 100%;

  &__main {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__background {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;

    @include respond-to(small) {
      background-attachment: scroll;
    }

    @supports (-webkit-touch-callout: none) {
      background-attachment: scroll;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;

    .homepage-messages {
      margin-top: 20px;
      border: 2px solid $light-yellow-background;
      border-radius: 4px;
      text-transform: uppercase;
      padding: 3px 30px;
      font-weight: 600;
      font-size: 22px;
      border-radius: 3px;
      letter-spacing: 2px;
      color: $light-yellow-background;
      cursor: pointer;
      transition: ease all 0.3s;

      &:hover {
        box-shadow: 0 0 3px 0 $box-shadow-color;
        background-color: $lightest-yellow-background;
        color: $white;
      }
    }

    .homepage-information {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      overflow: hidden;
      margin-top: 60px;

      @include respond-to(small) {
        margin: 40px 20px 0;
      }

      &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include respond-to(medium) {
          margin-left: 25px;
          max-width: 400px;
        }

        @include respond-to(small) {
          width: 90%;
        }

        &__announcements {
          margin-bottom: 10px;
        }
      }
    }
  }

  &__title {
    font-family: 'Bahnschrift';
    position: relative;
    text-align: center;
    font-size: 48px;
    color: $very-dark-blue;
    font-weight: 900;
    line-height: 56px;
    padding: 0 10px;
    letter-spacing: -1.5px;

    span {
      line-height: 56px;
    }

    @include respond-to(medium) {
      font-size: 74px;
      padding: 20px 0;

      br {
        display: none;
      }
    }
  }

  &__slogan {
    font-size: 22px;
    color: $olive;
    letter-spacing: -1px;
    text-align: center;
    padding: 0 10px;
    opacity: 0.7;

    @include respond-to(medium) {
      font-size: 30px;

      br {
        display: none;
      }
    }
  }

  &__scroll-mouse {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    display: none;
    cursor: pointer;

    @include respond-to(medium) {
      display: block;
    }

    .scroll-wrapper {
      width: 34px;
      height: 55px;

      .mouse {
        width: 3px;
        padding: 10px 15px;
        height: 50px;
        border: 2px solid #fff;
        border-radius: 25px;
        opacity: 0.75;

        .scroller {
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: #fff;
          animation-name: scroll;
          animation-duration: 2.2s;
          animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
          animation-iteration-count: infinite;
          position: relative;
          left: -3px;
        }
      }
    }

    @keyframes scroll {
      0% {
        opacity: 0;
      }
      10% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(15px);
        opacity: 0;
      }
    }
  }

  &__previews {
    padding-bottom: 10px;
  }
}
