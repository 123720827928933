@import 'public/styles/utils';

.preview-about {
  position: relative;
  background-size: contain;
  max-width: 1100px;
  width: 90%;
  margin: 40px auto 0;

  &__content {
    display: flex;
    flex-direction: column;

    p {
      font-size: 16px;
    }

    &__section {
      display: inline-flex;
      margin-top: 20px;
      flex-direction: column;
      align-items: flex-start;
    }

    .section-subtitle {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 900;
      color: $faded-green;
      padding: 4px 0;
      border-radius: 2px;
      flex-shrink: 0;
      text-align: center;

      @include respond-to(medium) {
        margin-right: 20px;
      }
    }

    .section-subcontent {
      font-size: 16px;
      margin-top: 4px;
      border-left: 2px solid $lighter-yellow-background;
      padding-left: 10px;
    }
  }

  .preview-more {
    margin: 25px 0 30px;

    a {
      border: 1px solid $very-dark-blue;
      color: $very-dark-blue;

      &:hover {
        background-color: $very-dark-blue;
        color: $white;
      }
    }
  }
}
