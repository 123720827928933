@import 'colors';

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'medium' {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'large' {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == 'small' {
    @media (max-width: 767px) {
      @content;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateX(-5px);
  }

  15% {
    transform: translateX(5px);
  }

  30% {
    transform: translateX(-3px);
  }

  45% {
    transform: translateX(3px);
  }

  60% {
    transform: translateX(-2px);
  }

  75% {
    transform: translateX(2px);
  }

  90% {
    transform: translateX(-1px);
  }

  100% {
    transform: translateX(1px);
  }
}

@mixin resize($resolution) {
  @if $resolution == 'small' {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $resolution == 'medium' {
    @media (min-width: 768px) {
      @content;
    }
  }
}
