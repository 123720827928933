@import 'public/styles/utils';

.ministries {
  &__intro {
    font-size: 16px;
    padding: 10px 25px 20px;
    text-align: center;
    font-weight: 600;
    color: $very-dark-blue;
    max-width: 1100px;
    margin: 0 auto;
  }

  &__preview {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 30px 25px 0;
    max-width: 1100px;
    margin: 0 auto;

    &__item {
      width: 49%;
      height: 70px;
      border-radius: 2px;
      margin: 5px 0;
      cursor: pointer;
      position: relative;
      box-shadow: 0 0 3px 0 $box-shadow-color;

      &:hover:after {
        background-color: $very-dark-blue-background-dark;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 2px;
        font-family: 'object-fit: contain;';
        background-size: cover !important;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        color: $white;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 800;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: block;
        transition: ease 0.3s all;
      }
    }

    @include respond-to(medium) {
      justify-content: space-between;

      &__item {
        width: 49%;
        height: 100px;
        margin: 5px 0;

        span {
          font-size: 24px;
        }
      }
    }

    @include respond-to(large) {
      &__item {
        width: 24%;
      }
    }
  }

  &__main {
    .ministry {
      &:not(:last-child) {
        padding-bottom: 30px;
      }

      &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: $very-dark-blue;
        height: 380px;

        img {
          width: 100%;
          height: 70%;
          object-fit: cover;
          font-family: 'object-fit: contain;';
          background-size: cover !important;

          @include respond-to(medium) {
            width: 65%;
            height: 100%;
          }

          @include respond-to(large) {
            width: 70%;
            height: 100%;
          }
        }

        &__titles {
          padding: 20px;
          display: block;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            display: flex;

            &:first-of-type {
              font-size: 30px;
              font-weight: 800;
              text-transform: uppercase;
              color: $white;
            }

            &:last-of-type {
              font-weight: 300;
              color: $aluminium;
            }
          }

          @include respond-to(medium) {
            justify-content: center;

            span:first-of-type {
              font-size: 32px;
            }
          }
        }
      }

      &__content {
        padding: 15px 25px 0;
        max-width: 1100px;
        margin: 0 auto;
      }

      &__section {
        &__title {
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 700;

          &.center-title {
            text-align: center;
          }

          span {
            border-bottom: 2px solid $light;
          }

          &:after {
            content: '';
            height: 10px;
            width: 100%;
            display: block;
          }
        }

        &__paragraphs {
          font-size: 16px;
        }

        &__images {
          margin-top: 25px;
        }
      }

      &:nth-of-type(2n) {
        .ministry__header img {
          @include respond-to(medium) {
            order: 2;
          }
        }
      }
    }
  }
}
